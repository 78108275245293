import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwittwerIcon from "@mui/icons-material/Twitter";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Grid, Link, Tooltip } from "@mui/material";
import { centered_flex_box, left_flex_box, right_flex_box } from "./Styles";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import StarsIcon from "@mui/icons-material/Stars";
import YouTube from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";

function Footer({ }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          bgcolor: "var(--secColor)",
          color: "var(--appBg)",
          padding: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold">
              {t("all_rights")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ ...centered_flex_box, flexDirection: "column" }}
          >
            <Typography fontWeight="bold">
              <NavLink className="a2" to="https://sllr.co/yampstore" target="_blank">
                {t("buy")}
              </NavLink>
            </Typography>
          </Grid>
          <Grid item xs={4} sx={right_flex_box}>
            <Typography fontWeight="bold">
              <NavLink
                to={"mailto:mina.khalil@integraegy.com"}
                target="_blank"
                className="a2"
              >
                Developed By Mina Khalil | Integra
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
