import { NEW_ROUND, NEW_ROUND_SUCCESS, NEW_ROUND_FAIL, PLAYERS, PLAYERS_SUCCESS, PLAYERS_FAIL, LOAD, LOAD_SUCCESS, LOAD_FAIL } from "../actions/types";

const initialState = {
    rounds: [],
    players: [],
    isLoading: false,
    isError: false,
};

export default function store(state = initialState, action) {
    const { type, round, players, rounds } = action;
    let newRounds = state.rounds
    if (round) {
        newRounds.push(round)
    }
    switch (type) {
        case NEW_ROUND:
            return {
                ...state,
                isLoading: true,
            };
        case NEW_ROUND_SUCCESS:
            return {
                ...state,
                rounds: newRounds,
                isLoading: false,
                isError: false,
            };
        case NEW_ROUND_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case PLAYERS:
            return {
                ...state,
                isLoading: true,
            };
        case PLAYERS_SUCCESS:
            return {
                ...state,
                players,
                isLoading: false,
                isError: false,
            };
        case PLAYERS_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                players,
                rounds,
                isLoading: false,
                isError: false,
            };
        case LOAD_FAIL:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
}