//New Round
export const NEW_ROUND_SUCCESS = "NEW_ROUND_SUCCESS";
export const NEW_ROUND_FAIL = "NEW_ROUND_FAIL";
export const NEW_ROUND = "NEW_ROUND";

//Players
export const PLAYERS_SUCCESS = "PLAYERS_SUCCESS";
export const PLAYERS_FAIL = "PLAYERS_FAIL";
export const PLAYERS = "PLAYERS";

//Players
export const LOAD_SUCCESS = "LOAD_SUCCESS";
export const LOAD_FAIL = "LOAD_FAIL";
export const LOAD = "LOAD";