import * as React from 'react';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, InputLabel, FormHelperText, FormControl, CircularProgress, Chip, Grid, TableCell, TableRow, TableHead, Table, TableContainer, TableBody, Paper, Dialog, IconButton, ToggleButtonGroup, ToggleButton, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material';
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import { MainInput, SecInput, centered_flex_box, main_button, sec_button } from '../app/components/Styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Logo from "../../src/app/components/screw-logo.jpg"
import { Image, notification } from 'antd';
import { addPlayers, newRound, loadGame, saveGame } from "../app/store/actions/commonActions"
import { Add, AddBoxOutlined, AddCircleOutline, Filter2, Language, Publish, Save, Start } from '@mui/icons-material';
import { useState } from 'react';
import { GiScrew } from "react-icons/gi";
import Cookies from 'js-cookie';

export const Home = ({ rounds, players, addPlayers, newRound, saveGame, loadGame }) => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const currentLocale = Cookies.get("i18next") || "en";
    const languages = [
        { name: "English", code: "en" },
        { name: "العربية", code: "ar", dir: "rtl" },
    ];

    const currentLangObj = languages.find((lang) => lang.code === currentLocale);

    const changeLang = () => {
        if (currentLangObj.code === "en") {
            navigate("/ar/")
            i18n.changeLanguage("ar")
        }
        if (currentLangObj.code === "ar") {
            navigate("/en/")
            i18n.changeLanguage("en")
        }
    }

    React.useEffect(() => {
        document.body.dir = currentLangObj.dir || 'ltr'
    }, [currentLangObj, currentLocale])

    const [playersDialog, setPlayersDialog] = useState(false)
    const newGame = () => {
        setPlayersDialog(true)
    }
    const closeGame = () => {
        setPlayersDialog(false)
    }
    const handleAddPlayers = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const playersToAdd = [];
        for (let entry of data.entries()) {
            if (entry[1] !== "") playersToAdd.push({ name: entry[1], id: entry[0] })
        }
        addPlayers(playersToAdd)
        closeGame()
    }

    const [roundDialog, setRoundDialog] = useState(false)
    const addRound = () => {
        setRoundDialog(true)
    }
    const closeRound = () => {
        setRoundDialog(false)
    }
    const [roundType, setRoundType] = useState("normal")
    const handleRoundTypeChange = ({ value }) => {
        setRoundType(value)
    }
    const handleAddRound = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const roundToAdd = {};
        roundToAdd["roundType"] = roundType
        for (let entry of data.entries()) {
            console.log(entry)
            if (entry[0].includes("player")) {
                roundToAdd[entry[0]] = roundType === "x2" ? parseInt(entry[1]) * 2 : parseInt(entry[1])
            } else {
                if (roundToAdd[entry[1]] !== 0) roundToAdd[entry[1]] = roundToAdd[entry[1]] * 2
            }
        }
        newRound(roundToAdd);
        saveGame();
        notification.info({ message: "Autosaving" })
        closeRound();
    }

    const getMin = () => {
        let min = Infinity
        for (let player of players) {
            let curr = rounds.reduce((accumulator, currentRound) => accumulator + currentRound[player.id], 0)
            if (curr < min) min = curr
        }
        return min
    }

    const getMax = () => {
        let max = 0
        for (let player of players) {
            let curr = rounds.reduce((accumulator, currentRound) => accumulator + currentRound[player.id], 0)
            if (curr > max) max = curr
        }
        return max
    }

    const getMine = (player) => {
        return rounds.reduce((accumulator, currentRound) => accumulator + currentRound[player.id], 0)
    }

    return (
        <Container>
            <Dialog
                open={playersDialog}
                onClose={closeGame}
                sx={{ color: "var(--secColor)", ...centered_flex_box }}
            >
                <Box component="form" onSubmit={handleAddPlayers} sx={{ ...centered_flex_box, flexDirection: "column", padding: 2, ...(isMobile ? {width: "80vw"}:{}) }}>
                    <Typography fontWeight="bold" fontSize={20}>{t("add_players")}</Typography>
                    <SecInput sx={{ marginY: 1 }} label={t("player", { number: 1 })} required name="player1" id="player1" />
                    <SecInput sx={{ marginBottom: 1 }} label={t("player", { number: 2 })} required name="player2" id="player2" />
                    <SecInput sx={{ marginBottom: 1 }} label={t("player", { number: 3 })} required name="player3" id="player3" />
                    <SecInput sx={{ marginBottom: 1 }} label={t("player", { number: 4 })} name="player4" id="player4" />
                    <SecInput sx={{ marginBottom: 1 }} label={t("player", { number: 5 })} name="player5" id="player5" />
                    <Button type="submit" sx={{ ...sec_button, mx: 2 }}><Start sx={{ ...(currentLangObj.dir === "rtl" ? { ml: 1 } : { mr: 1 }) }} />{t("start")}</Button>
                </Box>
            </Dialog>
            <Dialog
                open={roundDialog}
                onClose={closeRound}
                sx={{ color: "var(--secColor)", ...centered_flex_box }}
            >
                <Box component="form" onSubmit={handleAddRound} sx={{ ...centered_flex_box, flexDirection: "column", padding: 2, ...(isMobile ? {width: "80vw"}:{}) }}>
                    <Typography fontWeight="bold" fontSize={20}>{t("new_round")}</Typography>
                    <ToggleButtonGroup
                        value={roundType}
                        exclusive
                        color='primary'
                        onChange={(event) => handleRoundTypeChange(event.target)}
                        aria-label="Platform"
                    >
                        <ToggleButton sx={main_button} value="normal">{t("normal")}</ToggleButton>
                        <ToggleButton sx={main_button} value="x2">x2</ToggleButton>
                    </ToggleButtonGroup>
                    {players.map((player) => {
                        return <SecInput inputMode="numeric" type="number" inputProps={{ min: 0 }} sx={{ marginY: 1 }} label={player.name} required name={player.id} id={player.id} />
                    })}
                    <FormControl>
                        <FormLabel id="screw-label">{t("screw")}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="screw-label"
                            name="screw"
                        >
                            {players.map((player) => {
                                return <FormControlLabel value={player.id} control={<Radio />} label={player.name} />
                            })}
                        </RadioGroup>
                    </FormControl>
                    <Button type="submit" sx={{ ...sec_button, mx: 2 }}><Start sx={{ ...(currentLangObj.dir === "rtl" ? { ml: 1 } : { mr: 1 }) }} />{t("next")}</Button>
                </Box>
            </Dialog>
            <Box>
                <Button onClick={changeLang}><Language sx={{ color: "var(--secColor)", ...(currentLangObj.dir === "rtl" ? { ml: 1 } : { mr: 1 }) }} /> {currentLangObj.name}</Button>
            </Box>
            <Box sx={{ ...centered_flex_box, flexDirection: "column", paddingY: 2 }}>
                <Image height={150} preview={false} src={Logo} />
                <Typography sx={{ textAlign: "center" }} fontWeight="bold" fontSize={isMobile ? 40 : 60}>{t("welcome_text")}</Typography>
            </Box>
            <Box>
                {players.length === 0 && <Box sx={{ ...centered_flex_box }}>
                    <Button onClick={newGame} sx={{ ...sec_button, mx: 2 }}><Add sx={{ ...(currentLangObj.dir === "rtl" ? { ml: 1 } : { mr: 1 }) }} />{t("new_game")}</Button>
                    <Button onClick={loadGame} sx={{ ...sec_button, mx: 2 }}><Publish sx={{ ...(currentLangObj.dir === "rtl" ? { ml: 1 } : { mr: 1 }) }} />{t("load_game")}</Button>
                </Box>}
                {players.length > 0 &&
                    <Box sx={{ ...centered_flex_box, my: 2 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={"10vw"} align="center">{t("round")}</TableCell>
                                        {players.map((player) => {
                                            let width = 80 / players.length
                                            return (
                                                <TableCell width={`${width}vw`} key={player.id} align="center">{player.name}</TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rounds.map((round, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell width={"10vw"} align="center">{round.roundType === "x2" && <Filter2 sx={{ color: "red", ...(currentLangObj.dir === "rtl" ? { ml: 1 } : { mr: 1 }) }} />}{t("round_num", { number: idx + 1 })}</TableCell>
                                            {players.map((player) => {
                                                let width = 80 / players.length
                                                return (<TableCell sx={{ ...(round[player.id] === 0 ? { background: "green", color: "white !important" } : {}) }} width={`${width}vw`} align="center">
                                                    {round[player.id]}
                                                </TableCell>)
                                            })}
                                        </TableRow>
                                    ))}
                                    <TableRow key="add">
                                        <TableCell width={"10vw"} align='center'>
                                            <IconButton onClick={addRound} ><AddCircleOutline sx={{ color: "var(--appBg)" }} /></IconButton>
                                        </TableCell>
                                        {players.map((player) => {
                                            let width = 80 / players.length
                                            return (<TableCell sx={{ fontWeight: "bold", fontStyle: "italic", ...(getMin() === getMine(player) ? { background: "green", color: "white !important" } : {}), ...(getMax() === getMine(player) ? { background: "red", color: "white !important" } : {}) }} width={`${width}vw`} align="center">
                                                {getMax() === getMine(player) && <GiScrew style={{ ...(currentLangObj.dir === "rtl" ? { marginLeft: 5 } : { marginRight: 5 }) }} />}{getMine(player)}
                                            </TableCell>)
                                        })}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>}
                {players.length > 0 && <Box sx={{ ...centered_flex_box, mb: 2 }}>
                    <Button onClick={saveGame} sx={{ ...sec_button, mx: 2 }}><Save sx={{ ...(currentLangObj.dir === "rtl" ? { ml: 1 } : { mr: 1 }) }} />{t("save_game")}</Button>
                    <Button onClick={newGame} sx={{ ...sec_button, mx: 2 }}><Add sx={{ ...(currentLangObj.dir === "rtl" ? { ml: 1 } : { mr: 1 }) }} />{t("new_game")}</Button>
                </Box>}
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    players: state?.round?.players,
    rounds: state?.round?.rounds
});

const mapDispatchToProps = { addPlayers, newRound, saveGame, loadGame };

export default connect(mapStateToProps, mapDispatchToProps)(Home);