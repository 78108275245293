import { NEW_ROUND, NEW_ROUND_SUCCESS, NEW_ROUND_FAIL, PLAYERS, PLAYERS_SUCCESS, PLAYERS_FAIL, LOAD, LOAD_SUCCESS, LOAD_FAIL } from "./types";

import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { notification } from "antd";

const currentLocale = Cookies.get("i18next") || "en";

export const newRound = (data) => (dispatch) => {
    dispatch({ type: NEW_ROUND });
    try {
        dispatch({ type: NEW_ROUND_SUCCESS, round: data });
    } catch (err) {
        dispatch({ type: NEW_ROUND_FAIL });
        console.log(err)
    }
};

export const addPlayers = (data) => (dispatch) => {
    dispatch({ type: PLAYERS });
    try {
        dispatch({ type: PLAYERS_SUCCESS, players: data });
    } catch (err) {
        dispatch({ type: PLAYERS_FAIL });
        console.log(err)
    }
};

export const saveGame = (data) => (dispatch, useState) => {
    const players = useState().round.players
    const rounds = useState().round.rounds
    console.log(players)

    localStorage.setItem("screwPlayers", JSON.stringify(players))
    localStorage.setItem("screwRounds", JSON.stringify(rounds))
};

export const loadGame = (data) => (dispatch) => {
    dispatch({ type: LOAD });
    try {
        const players = JSON.parse(localStorage.getItem("screwPlayers"))
        const rounds = JSON.parse(localStorage.getItem("screwRounds"))
        if(!players || !rounds) throw Error()
        dispatch({ type: LOAD_SUCCESS, players, rounds });
    } catch (err) {
        dispatch({ type: LOAD_FAIL });
        notification.info({message: currentLocale === "ar" ? "لا يوجد لعبة محفوظة":"No Game Found"})
    }
};